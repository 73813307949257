import { __decorate } from "tslib";
import { Component, Vue, Watch } from 'vue-property-decorator';
// components
import Btn from '@/components/Button/Btn.vue';
import FormWrap from '@/components/Elements/FormWrap.vue';
import PublicPageTitle from '@/components/Elements/PublicPageTitle.vue';
import TextInput from '@/components/FormInputs/TextInput/TextInput.vue';
import PasswordInput from '@/components/FormInputs/PasswordInput/PasswordInput.vue';
let Register = class Register extends Vue {
    constructor() {
        super(...arguments);
        this.form = {
            name: '',
            email: '',
            token: '',
            password: '',
        };
        this.errors = {
            email: [],
            token: [],
            password: [],
        };
        this.confirm_password = '';
        this.validPassword = false;
    }
    onPasswordConfirmChange() {
        this.validPassword = this.form.password === this.confirm_password;
    }
    async onEmailChange() {
        this.errors.email = [];
    }
    async onTokenChange() {
        this.errors.token = [];
    }
    async onPasswordChange() {
        this.errors.password = [];
    }
    mounted() {
        // We get the query params from the url to assist the user
        this.form.email = Array.isArray(this.$route.query.email) ? (this.$route.query.email[0] != null ? this.$route.query.email[0] : "") : (this.$route.query.email != null ? this.$route.query.email : "");
        this.form.name = Array.isArray(this.$route.query.name) ? (this.$route.query.name[0] != null ? this.$route.query.name[0] : "") : (this.$route.query.name != null ? this.$route.query.name : "");
        this.form.token = Array.isArray(this.$route.query.code) ? (this.$route.query.code[0] != null ? this.$route.query.code[0] : "") : (this.$route.query.code != null ? this.$route.query.code : "");
    }
    async resendToken() {
        try {
            await this.$store.dispatch('auth/sendVerifyToken', this.form);
        }
        catch (e) {
            this.errors = e.data && e.data.messages ? e.data.messages : [];
        }
    }
    async completeRegister() {
        try {
            if (this.validPassword) {
                await this.$store.dispatch('auth/verifyUserToken', {
                    email: this.form.email,
                    token: this.form.token,
                    password: this.form.password,
                });
                // Push the user into the onboarding process
                await this.$router.push('/onboarding');
            }
        }
        catch (e) {
            this.errors = e.data && e.data.messages ? e.data.messages : [];
        }
    }
};
__decorate([
    Watch('confirm_password')
], Register.prototype, "onPasswordConfirmChange", null);
__decorate([
    Watch('form.email')
], Register.prototype, "onEmailChange", null);
__decorate([
    Watch('form.token')
], Register.prototype, "onTokenChange", null);
__decorate([
    Watch('form.password')
], Register.prototype, "onPasswordChange", null);
Register = __decorate([
    Component({
        components: { Btn, FormWrap, PublicPageTitle, TextInput, PasswordInput },
        metaInfo() {
            return {
                title: 'Africa Geographic | Verify Registration',
                meta: [
                    {
                        name: 'description',
                        content: 'Africa Geographic',
                    },
                ],
                link: [{ rel: 'canonical', href: `${process.env.VUE_APP_URL}/verify` }],
            };
        },
    })
], Register);
export default Register;
